import React from 'react';
import './base.css';
//import '../../theme/profi_1.0.1/src/assets/scss/style.scss'
import Container from './container';
import Navigation from './navigation';

class Template extends React.Component {
    render() {
        const { children } = this.props;

        return (
            <Container>
                <Navigation />
                {children}
            </Container>
        );
    }
}

export default Template;
