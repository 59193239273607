import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styles from './navigation.module.css';
import {buildLinkPath} from '../util/linkPaths';

export const Navigation = () => {
    const data = useStaticQuery(graphql`
    query MainNavigation {
      contentfulNavigaton(title: {eq: "Navigation"}) {
        navigationLinks {
          title
          slug
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
    }`);
    const navLinks = data.contentfulNavigaton.navigationLinks.map(({title, slug, sys}) => <li key={slug} className={styles.navigationItem}><Link to={buildLinkPath(slug, sys.contentType.sys.id)}>{title}</Link></li>);
    return (
        <nav role="navigation">
            <ul className={styles.navigation}>
                {navLinks}
                <li className={styles.navigationItem}>
                    <Link to="/">Home</Link>
                </li>
                <li className={styles.navigationItem}>
                    <Link to="/blog/">Blog</Link>
                </li>
            </ul>
        </nav>
    );
};

export default Navigation;