import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import styles from './article-preview.module.css';

export const ArticlePreview = ({ article }) => (
    <div className={styles.preview}>
        <h3 className={styles.previewTitle}>
            <Link to={`/blog/${article.slug}`}>{article.title}</Link>
        </h3>
        <small>{article.publishDate}</small>
        <p
            dangerouslySetInnerHTML={{
                __html: article.description.childMarkdownRemark.html,
            }}
        />
    </div>
);

export default ArticlePreview;